import React from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import {Button, ButtonGroup} from '@chakra-ui/react'
import {
    FiPlusCircle
} from 'react-icons/fi';

export default function index() {
    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Repay</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Repay</li>
                            </ol>
                        </nav>
                        <Link className="btn btn-app-default " to="/repay/create"><Button leftIcon={<FiPlusCircle/>}
                                                                                          colorScheme='brand'>New</Button></Link>
                    </div>

                    <DataTable column={[

                        {label: "ID Repay", field: "id"},
                        {label: "Client", field: "client", render: ["firstName"]},
                        {label: "Request", field: "request", render: ["id"]},
                        {label: "Amount pay($)", field: "amount"},

                    ]}

                               filterOption={false}
                               filterLoader={() => {
                               }}
                               element={"repay"}
                               resource={resources.type}


                    />
                </div>
            </div>

        </div>
    )
}
