import Axios from "../Axios";

const company = {
    read(limit: number, page: number, rule: null) {
        return Axios.get('/request?limit=' + limit + "&page=" + page + "&" + rule,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    readOne(id: string) {
        return Axios.get('/request/' + id,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object, token: string) {
        return Axios.post('/request', data,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    update(id: string, data: object) {
        return Axios.put('/request/' + id, data,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/request/' + id,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },


}

export default company;
