import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import {
    Link, useParams
} from "react-router-dom";
import resources from '../../api';
import Select from 'react-select'
import config from '../../constants';
import { Field } from '../../components/Field';
import { ApiErrors } from "../../components/api"
import {
    Button, ButtonGroup, useToast, useDisclosure, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input
} from '@chakra-ui/react'

import {
    FiSave,
    FiImage
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";


export default function Edit() {
    const [data, setData] = useState({})
    const [loader, setLoader] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false)
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()

    async function onGet() {

        let mat = await resources.user.readOne(id)
        if (mat.status === 200) {
            setData(mat.data)
        }

    }

    useEffect(() => {
        onGet();
    }, [])






    async function create(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let object = {};
        formData.forEach((value, key) => object[key] = value);
       resources.user.update(id, object )

            .then((res)=>{
                if (res.status == 200) {
                    setLoader(false)
                    toast({
                        title: 'Client updated.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/materials");
                }
            }).catch((error)=>{

                console.log(error.response.data.errors)
                setLoader(false)
                if (error.response.data.errors)
                    error.response.data.errors.forEach(el=>{
                        toast({
                            title: el.field +": " + el.message,
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                    })


            })

    }


    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Clients</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/clients">Clients</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">update client</li>
                            </ol>
                        </nav>


                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>


                            <div className='card mt-3'>

                                <div className='card-body'>
                                    <form onSubmit={create}>

                                        {/*<div className="mb-3">*/}
                                        {/*    <Field name="name" required error={errors?.name} defaultValue={data?.name}>*/}
                                        {/*        Name*/}
                                        {/*    </Field>*/}
                                        {/*</div>*/}

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">First name <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                   name="firstName" defaultValue={data?.firstName}/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Last name <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                   name="lastName" defaultValue={data?.lastName}/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Phone
                                                number <span
                                                    style={{color: "red"}}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                   name="phone" defaultValue={data?.phone}/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Email
                                                address </label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="email" defaultValue={data?.email} readOnly/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Mobile money
                                                phone</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="mobileMoney" defaultValue={data?.mobileMoney}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Bank
                                                name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="bankName" defaultValue={data?.bankName}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Account
                                                name</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="accountName" defaultValue={data?.accountName}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Account
                                                number</label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="accountNumber" defaultValue={data?.accountNumber}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">City <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                   name="city" defaultValue={data?.city}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Address <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                   name="address" defaultValue={data?.address}/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Card <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                   name="cardId" defaultValue={data?.cardId}/>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Code
                                                promo </label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                   name="ref" defaultValue={data?.ref} readOnly/>
                                        </div>

                                        {/*<div className="mb-3">*/}
                                        {/*    <label htmlFor="exampleInputEmail1" className="form-label">Card <span*/}
                                        {/*        style={{color: "red"}}>*</span></label>*/}
                                        {/*    <input type="file" required className="form-control" id="exampleInputEmail1"*/}
                                        {/*           name="cardId"/>*/}
                                        {/*</div>*/}

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Password <span
                                                style={{color: "red"}}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                   name="plainPassword" />
                                        </div>

                                        <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
