import React, {useEffect, useState, useRef} from 'react'
import {
    Box,
    Button,
    FormControl, FormLabel, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Skeleton, Stack, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure, useToast
} from "@chakra-ui/react";
import TrData from "./TrData";
import resources from "../api";

const Amount = ({loader, data, onGet}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const [inst, setInst] = useState();
    const [load, setLoad] = useState(false);

    const toast = useToast()

    function addInstallment() {
        setLoad(true)

        resources.setting.amount.create({
            value: inst
        })
            .then((res) => {

                setLoad(false)
                onGet()
                onClose()
                toast({
                    title: 'Amount created.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })


            }).catch((error) => {

            setLoad(false)
            toast({
                title: "Erreur",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })


        })
    }

    return (
        <div>
            <Button colorScheme='brand' mb={3} onClick={onOpen} ref={finalRef}>New</Button>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader> New amount</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Value</FormLabel>
                            <Input ref={initialRef} name='value' onChange={(e) => {
                                setInst(e.target.value)
                            }}/>
                        </FormControl>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='brand.500' backgroundColor="brand.500" mr={3} onClick={addInstallment}
                                isLoading={load}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box backgroundColor="#fff">

                {
                    loader ? <Stack>
                            <Skeleton height='40px'/>
                            <Skeleton height='40px'/>
                            <Skeleton height='40px'/>
                        </Stack> :
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>Action</Th>
                                        <Th isNumeric>Value ($)</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        data.map((el, key) => {
                                            return <TrData data={el} key={key} onRefresh={onGet} action="amount"/>
                                        })
                                    }

                                </Tbody>

                            </Table>
                        </TableContainer>

                }


            </Box>
        </div>
    );

}

export default Amount
