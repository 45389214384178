import React, {useState} from 'react';
import {
    Button,
    FormControl, FormLabel, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Td, Tr, useDisclosure, useToast
} from "@chakra-ui/react";
import {FiEye} from "react-icons/fi";
import resources from "../api";

function TrData({data, key, onRefresh, action}) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [value, setValue] = useState();
    const [loader, setLoader] = useState(false);
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const toast = useToast()

    function onUpdate() {
        setLoader(true)

        if (action === "installment") {
            resources.setting.installment.update({
                value: value
            }, data.id)
                .then((res) => {

                        setLoader(false)
                        toast({
                            title: 'Setting updated.',
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
                        onRefresh()
                        onClose();


                })
                .catch((er) => {

                    setLoader(false)
                    toast({
                        title: "Error",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                })

        } else {
            resources.setting.amount.update({
                value: value
            }, data.id)
                .then((res) => {

                        setLoader(false)
                        toast({
                            title: 'Setting updated.',
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
                        onRefresh()
                        onClose();


                })
                .catch((er) => {

                    setLoader(false)
                    toast({
                        title: "Error",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })


                })

        }


    }

    return (
        <Tr key={key}>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Update value</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>

                        <FormControl mt={4}>
                            <FormLabel>Value</FormLabel>
                            <Input defaultValue={data.value} onChange={(e) => setValue(e.target.value)}/>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button isLoading={loader} colorScheme='brand.500' backgroundColor="brand.500" mr={3}
                                onClick={onUpdate}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Td><FiEye onClick={onOpen}/></Td>
            <Td isNumeric>{data.value}</Td>
        </Tr>
    );
}

export default TrData;
