import Axios from "../Axios";

const type = {
    read(limit: number, page: number, rule: null) {
        return Axios.get('/repay?limit=' + limit + "&page=" + page + "&" + rule,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    readOne(id: string) {
        return Axios.get('/repay/' + id,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object) {
        return Axios.post('/repay', data,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    update(id: string, data: object) {
        return Axios.put('/repay/' + id, data,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/repay/' + id,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    },


}

export default type;
