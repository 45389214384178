import React, {useState} from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import {Button, ButtonGroup, useToast} from '@chakra-ui/react'
import {
    FiCheckCircle, FiCreditCard,
    FiEdit,
    FiPlusCircle, FiXCircle
} from 'react-icons/fi';
import {Badge} from '@chakra-ui/react'


export default function Company() {
    const [loader, setLoader] = useState(false)
    const toast = useToast()

    function updateRequest(request, status, refresh, setLoading) {
        setLoader(true)
        setLoading(true)
        console.log(request)


        resources.company.update(request.id, {
            status: status
        })

            .then((res) => {

                resources.user.update(request.client.id, {
                    credit: request.client.credit ? parseFloat(request.client.credit) + parseFloat(request.amount.value) : parseFloat(request.amount.value)
                }).then((r) => {
                    setLoader(false)
                    toast({
                        title: 'Request update.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setLoading(false)
                    refresh()

                }).catch((e) => {
                    setLoader(false)
                    setLoading(false)
                    toast({
                        title: "Erreur",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                })


            }).catch((error) => {

            setLoader(false)
            setLoading(false)
            toast({
                title: "Erreur",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })

    }

    function updateRequestDeposit(request, refresh, setLoading) {
        setLoader(true)
        setLoading(true)
        resources.company.update(request, {
            deposit: true
        })

            .then((res) => {

                setLoader(false)
                toast({
                    title: 'Request update.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                setLoading(false)
                refresh()

            }).catch((error) => {
            setLoading(false)
            setLoader(false)
            toast({
                title: "Erreur",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })


        })

    }

    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Requests</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Requests</li>
                            </ol>
                        </nav>
                        <Link className="btn btn-app-default " to="/request/create"><Button
                            leftIcon={<FiPlusCircle/>}
                            colorScheme='brand'>New</Button></Link>
                    </div>

                    <DataTable column={[
                        {label: "ID Request", field: "id"},
                        {label: "Client", field: "client", render: ["firstName"]},
                        {label: "Amount", field: "amount", render: ["value"]},
                        {label: "Installments", field: "installment", render: ["value"]},
                        {
                            label: "Status", field: "status", renderStyle: (data) => {
                                return (<>
                                    {
                                        data.status === 1 && <Badge colorScheme='yellow'>pending</Badge>
                                    }
                                    {
                                        data.status === 2 && <Badge colorScheme='blue'>accept</Badge>
                                    }
                                    {
                                        data.status === 3 && <Badge colorScheme='red'>decline</Badge>
                                    }
                                    {
                                        data.status === 4 && <Badge colorScheme='purple'>partially repay</Badge>
                                    }
                                    {
                                        data.status === 5 && <Badge colorScheme='green'>fully repay</Badge>
                                    }

                                </>)
                            }
                        },
                        {
                            label: "Deposit", field: "deposit", renderStyle: (data) => {
                                return (<>
                                    {
                                        data.deposit ? <Badge colorScheme='green'>perform</Badge> :
                                            <Badge colorScheme='red'>not perform</Badge>
                                    }

                                </>)
                            }
                        },
                        {
                            label: "Date", field: "createdAt"
                        },
                    ]}
                               filterOption={false}
                               filterLoader={() => {
                               }}

                               element={"company"}
                               resource={resources.company}
                               action={(el, onGet, setLoader) => {
                                   return (
                                       <>

                                           <li>
                        <span className="dropdown-item">
                        <Button colorScheme='brand.500'
                                leftIcon={<FiCheckCircle/>}
                                variant='ghost'
                                onClick={() => updateRequest(el, 2, onGet, setLoader)}>
                        Accept
                        </Button>

                        </span>
                                           </li>

                                           <li>


                        <span className="dropdown-item">
                        <Button colorScheme='brand.500'
                                leftIcon={<FiXCircle/>}
                                variant='ghost'
                                onClick={() => updateRequest(el, 3, onGet, setLoader)}>
                        Decline
                        </Button>

                        </span>
                                           </li>
                                           <li>
                        <span className="dropdown-item">

                        <Button colorScheme='brand.500'
                                leftIcon={<FiCreditCard/>}
                                variant='ghost'
                                onClick={() => updateRequest(el, 5, onGet, setLoader)}>
                       Fully repay
                        </Button>
                        </span>
                                           </li>

                                           <li>
                        <span className="dropdown-item">

                        <Button colorScheme='brand.500'
                                leftIcon={<FiCreditCard/>}
                                variant='ghost'
                                onClick={() => updateRequestDeposit(el.id, onGet, setLoader)}>
                       Deposit done
                        </Button>
                        </span>
                                           </li>
                                       </>
                                   )

                               }}


                    />
                </div>
            </div>

        </div>
    )
}
