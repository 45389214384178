import React, {useState, useEffect} from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import resources from '../../api';
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import {Button, Alert, AlertIcon, Image, useToast} from '@chakra-ui/react'
import {
    FiSave
} from 'react-icons/fi';
import {useNavigate} from "react-router-dom";
import icon from "../../assets/icon.png"


export default function Create() {
    const [loader, setLoader] = useState(false)
    const [show, setShow] = useState(false)
    const toast = useToast()
    const navigate = useNavigate();

    async function goToMobile(data) {
        window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
        return data;
    }

    function create(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        resources.user.createClient(object)
            .then((res) => {

                setLoader(false)
                toast({
                    title: 'Accont created.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                setShow(true)
                goToMobile({
                    username: formData.get("email"),
                    password: formData.get("password")
                })

            }).catch((error) => {

            setLoader(false)
            toast({
                title: "Erreur",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })


        })


    }

    return (
        <div className="bg-gray m-5">

            <div className='row justify-content-center'>
                <div className='col-lg-6'>
                    <div className='row justify-content-center'>
                        <Image src={icon} width={300} alt="BAN-GO"/>
                        <p className="text-center fw-bold card-title">Ban-go</p>
                    </div>

                    <div className='card'>


                        <div className='card-body'>
                            <form onSubmit={create}>


                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">First name <span
                                        style={{color: "red"}}>*</span></label>
                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                           name="firstName"/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Last name <span
                                        style={{color: "red"}}>*</span></label>
                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                           name="lastName"/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Phone
                                        number <span
                                            style={{color: "red"}}>*</span></label>
                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                           name="phone"/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email
                                        address </label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           name="email"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Mobile money
                                        phone</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           name="mobileMoney"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Bank
                                        name</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           name="bankName"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Account
                                        name</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           name="accountName"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Account
                                        number</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           name="accountNumber"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">City <span
                                        style={{color: "red"}}>*</span></label>
                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                           name="city"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Address <span
                                        style={{color: "red"}}>*</span></label>
                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                           name="address"/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Card <span
                                        style={{color: "red"}}>*</span></label>
                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                           name="cardId"/>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Code
                                        promo </label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                           name="ref"/>
                                </div>

                                {/*<div className="mb-3">*/}
                                {/*    <label htmlFor="exampleInputEmail1" className="form-label">Card <span*/}
                                {/*        style={{color: "red"}}>*</span></label>*/}
                                {/*    <input type="file" required className="form-control" id="exampleInputEmail1"*/}
                                {/*           name="cardId"/>*/}
                                {/*</div>*/}

                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Password <span
                                        style={{color: "red"}}>*</span></label>
                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                           name="plainPassword"/>
                                </div>


                                <Button type="submit" leftIcon={<FiSave/>} isLoading={loader}
                                        colorScheme='brand' className="w-100">Save</Button>
                            </form>

                        </div>
                        {
                            show && <Alert status='success'>
                                <AlertIcon/>
                                Veillez noter que votre compte a été créé avec succès ! Vous pouvez fermer cette page et
                                vous connecter dans l’application.
                            </Alert>
                        }


                    </div>

                </div>

            </div>


        </div>
    )
}
