import Axios from "../Axios";

const setting = {

    installment: {
        read(limit: number, page: number, rule: null) {
            return Axios.get('/installment?limit=' + limit + "&page=" + page + "&" + rule,{
                headers: {
                    "Authorization":"Bearer " + localStorage.getItem("userToken")
                }
            })
        },
        create(data: object) {
            return Axios.post('/installment', data,{
                headers: {
                    "Authorization":"Bearer " + localStorage.getItem("userToken")
                }
            })
        },
        update(data: object, id: string) {
            return Axios.put('/installment/' + id, data,{
                headers: {
                    "Authorization":"Bearer " + localStorage.getItem("userToken")
                }
            })
        },
    },

    amount: {
        read(limit: number, page: number, rule: null) {
            return Axios.get('/amount?limit=' + limit + "&page=" + page + "&" + rule,{
                headers: {
                    "Authorization":"Bearer " + localStorage.getItem("userToken")
                }
            })
        },
        create(data: object) {
            return Axios.post('/amount', data,{
                headers: {
                    "Authorization":"Bearer " + localStorage.getItem("userToken")
                }
            })
        },
        update(data: object, id: string) {
            return Axios.put('/amount/' + id, data,{
                headers: {
                    "Authorization":"Bearer " + localStorage.getItem("userToken")
                }
            })
        },
    },


}

export default setting;
