import React, {useEffect, useState, useRef} from 'react'
import Header from '../../components/Header'
import {
    Link, useNavigate
} from "react-router-dom";
import Icon from '../../ui/Icon';
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import {Box, Button, ButtonGroup, useToast} from '@chakra-ui/react'
import {
    FiEye,
    FiMoreVertical,
    FiPlusCircle
} from 'react-icons/fi';
import setting from "../../api/setting";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import {Stack, Skeleton} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    Input,
    FormLabel,

} from '@chakra-ui/react'
import {Tabs, TabList, TabPanels, Tab, TabPanel} from '@chakra-ui/react'
import TrData from "../../components/TrData";
import Installment from "../../components/Installment";
import Amount from "../../components/Amount";

export default function Setting() {
    const [data, setData] = useState([]);
    const [amount, setAmount] = useState([]);
    const [loader, setLoader] = useState(true);
    const {isOpen, onOpen, onClose} = useDisclosure()


    const initialRef2 = React.useRef(null)
    const finalRef2 = React.useRef(null)

    const toast = useToast()


    function onGet() {

        resources.setting.installment.read(50, 1)
            .then((res) => {
                if (res) {
                    setData(res.data.items)
                    setLoader(false)
                }
            })
            .catch((er) => {
                console.log(er)
                setLoader(false)
            })


        resources.setting.amount.read(50, 1)
            .then((res) => {
                if (res) {
                    setAmount(res.data.items)
                    setLoader(false)
                }
            })
            .catch((er) => {
                console.log(er)
                setLoader(false)
            })


    }

    useEffect(() => {
        onGet()
    }, []);


    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Settings</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>

                    <Tabs colorScheme='green'>
                        <TabList>
                            <Tab>Installment</Tab>
                            <Tab>Amount</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Installment data={data} loader={loader} onGet={onGet}/>
                            </TabPanel>
                            <TabPanel>
                                <Amount data={amount} loader={loader} onGet={onGet}/>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>


                </div>
            </div>

        </div>
    )
}
