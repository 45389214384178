import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import {
    Link
} from "react-router-dom";
import resources from '../../api';
import {Button, useToast} from '@chakra-ui/react'
import {
    FiPlusCircle,
    FiSave
} from 'react-icons/fi';
import {useNavigate} from "react-router-dom";
import InvoiceItem from "../../components/InvoiceItem";

export default function Create() {
    const [loader, setLoader] = useState(false)
    const toast = useToast()
    const navigate = useNavigate();

    const [total, setTotal] = useState(0),
        [reduct, setReduct] = useState(0),
        [items, setItems] = useState([{
            product_id: '',
            quantity: 0,
        }]),
        [article, setArticle] = useState([]),
        [drivers, setDrivers] = useState([]),
        [driver, setDriver] = useState(),
        [sellers, setSellers] = useState([]),
        [seller, setSeller] = useState(),
        [cars, setCars] = useState([]),
        [car, setCar] = useState(),
        [local, setLocal] = useState()

    async function onGet() {


        let sell = await resources.user.read(100000, 1)
        if (sell.status === 200) {
            let a = []
            sell.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.firstName + " " + el.lastName,
                })
            })
            setSellers(a)
        }

        let cus = await resources.setting.installment.read(100000, 1)
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.value,
                })
            })
            setCars(a)
        }


        let car = await resources.setting.amount.read(100000, 1)
        if (car.status === 200) {
            let a = []
            car.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.value,
                })
            })
            setDrivers(a)
        }
    }

    useEffect(() => {
        onGet();

    }, [])


    async function onCreate(e) {
        e.preventDefault()
        setLoader(true)

        let formData = new FormData()
        formData.append("client", seller)
        formData.append("installment", car)
        formData.append("amount", driver)
        formData.append("status", 1)

        resources.company.create(formData)

            .then((res) => {

                    setLoader(false)
                    toast({
                        title: 'Request created.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/requests");

            }).catch((error) => {

            setLoader(false)
            toast({
                title: "Erreur",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })


        })


    }

    return (
        <div>
            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Request</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/requests">Request</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">new request</li>
                            </ol>
                        </nav>

                        <Button isLoading={loader} leftIcon={<FiSave/>} colorScheme='brand'
                                onClick={onCreate}>Save</Button>
                    </div>
                    <div className="row  justify-content-center mt-3 mb-3">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                       className="form-label">Client <span
                                                    style={{color: "red"}}>*</span></label>
                                                <Select onChange={(e) => setSeller(e.value)} options={sellers}/>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                       className="form-label">Amount <span
                                                    style={{color: "red"}}>*</span></label>
                                                <Select onChange={(e) => setDriver(e.value)} options={drivers}/>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                       className="form-label">Installment <span
                                                    style={{color: "red"}}>*</span></label>
                                                <Select onChange={(e) => setCar(e.value)} options={cars}/>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
