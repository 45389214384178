import Axios from "../Axios";

const report = {
    create(data:any) {
        return Axios.post('/report/request', data,{
            headers: {
                "Authorization":"Bearer " + localStorage.getItem("userToken")
            }
        })
    }
}

export default report;
