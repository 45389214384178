import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../api";

import {
    Badge,
    Button,
    ButtonGroup,
    FormControl,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast
} from '@chakra-ui/react'
import {FiMessageCircle, FiPlusCircle} from 'react-icons/fi';
import axios from "axios";


const Av = ({code}) => {
    const [pers, setPers] = useState();

    function onGetUserCode() {
        return resources.user.readOneBy("codePromo=" + code).then((res) => {
                setPers(res.data.items[0])
            }
        ).catch((er) => {

            }
        )
    }

    useEffect(() => {
        onGetUserCode()
    }, [])


    if (pers) return <Badge colorScheme='blue'>{pers.firstName} {pers.lastName} </Badge>
}

export default function Material() {

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [filter, setFilter] = useState(false)
    const [filterOption, setFilterOption] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [destinator, setDestinator] = useState({});

    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const toast = useToast()


    function filerArticle(e) {
        e.preventDefault();
        setFilter(true)
        let formData = new FormData(e.target);
        let el = '';
        if (formData.get('lastName')) {
            el = "&lastName=" + formData.get('lastName')
        }
        if (formData.get('id')) {
            el += "&id=" + formData.get('id')
        }

        window.location.replace("/clients?" + el)

    }

    function onChangeFilterLoader() {
        setFilter(false)
    }

    function handleMessage(el) {
        setDestinator(el)
        onOpen()
    }


    function SendMessage() {
        setLoading(true)
        console.log(destinator)
        axios.post("https://gg2ln8.api.infobip.com/messages-api/1/messages",
            {
                messages: [
                    {
                        "channel": "SMS",
                        "sender": "BANGO",
                        "destinations": [

                            {
                                "to": destinator.phone
                            },
                        ],
                        "content": {
                            "body": {
                                "text": message,
                                "type": "TEXT"
                            }
                        }
                    }
                ]
            }, {
                headers: {
                    "Authorization": " App 77057fbda9f1aff2fb3a513d78e02b27-4485cf17-8c6d-41c8-8541-87946bc77113",
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
            .then((res) => {
                toast({
                    title: 'Message sent.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                setLoading(false)
                onClose()
            })
            .catch((er) => {
                toast({
                    title: 'Error.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
                setLoading(false)
            })


    }


    return (
        <div>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Send message</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        <FormControl>
                            <Input ref={initialRef} placeholder='Your message' size='lg' onChange={(e) => {
                                setMessage(e.target.value)
                            }}/>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='brand' mr={3} isLoading={loading} onClick={SendMessage}>
                            Send
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Clients</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Clients</li>
                            </ol>
                        </nav>
                        <div className="d-flex ">

                            <ButtonGroup gap='2'>
                                {/*{*/}
                                {/*    filterOption ? <Button leftIcon={<FiFilter />} colorScheme='red' onClick={() => setFilterOption((e) => !e)} >Close filter</Button>*/}
                                {/*        : <Button leftIcon={<FiFilter />} colorScheme='green' onClick={() => setFilterOption((e) => !e)} > Open filter</Button>*/}
                                {/*}*/}

                                <Link to="/client/create"><Button leftIcon={<FiPlusCircle/>}
                                                                  colorScheme='brand'>New</Button></Link>
                            </ButtonGroup>
                        </div>

                    </div>

                    <div className="card card-body mb-3 mt-4">
                        <form onSubmit={filerArticle}>
                            <div className="row">

                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1"
                                           placeholder="Name" name="lastName"/>
                                </div>


                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1"
                                           placeholder="Id number" name="id"/>
                                </div>


                                <div className="col">
                                    <Button isLoading={filter} className="w-100" colorScheme='brand'
                                            type="submit"> Apply</Button>
                                </div>

                                <div className="col">
                                    <Button className="w-100" colorScheme='red'
                                            type="button" onClick={() => window.location.replace("/clients")}> Reset
                                        filter</Button>
                                </div>

                            </div>

                        </form>

                    </div>

                    <DataTable column={[
                        {label: "ID Number", field: "id"},
                        {label: "Code promo", field: "codePromo"},
                        {
                            label: "Reference", field: "ref", renderStyle: (data) => {
                                return <Av code={data.ref}/>
                            }
                        },
                        {label: "First name", field: "firstName"},
                        {label: "Last name", field: "lastName"},
                        {label: "Credit ($)", field: "credit"},
                        {label: "Email ", field: "email"},
                        {label: "Phone number ", field: "phone"},
                        {label: "City ", field: "city"},
                        {label: "Address ", field: "address"},
                        {label: "Bank name ", field: "bankName"},
                        {label: "Account number ", field: "accountNumber"},
                        {label: "Account name ", field: "accountName"},
                        {label: "Mobile money number ", field: "mobileMoney"},

                    ]}

                               filterOption={filterOption}
                               filterLoader={onChangeFilterLoader}
                               element={"client"}
                               update
                               action={(el, onGet, setLoader) => <>
                                   <li>

                                       <Button colorScheme='brand.500'
                                               leftIcon={<FiMessageCircle/>}
                                               variant='ghost'
                                               onClick={() => handleMessage(el)}>
                                           Send
                                       </Button>
                                   </li>
                               </>}
                               resource={resources.user}


                    />
                </div>
            </div>

        </div>
    )
}
