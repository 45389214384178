import React, {useState} from "react"
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Stack,
    Image,
    useDisclosure,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    CloseButton
} from '@chakra-ui/react';
import log from "../../assets/log.svg"
import resources from "../../api";
import {Navigate} from "react-router-dom";

export default function LoginPage() {

    const [loader, setLoader] = useState(false), [error, setError] = useState(false), [navigate, setNavigate] = useState(false)
    const {
        isOpen: isVisible,
        onClose,
        onOpen,
    } = useDisclosure({defaultIsOpen: false})

    const CONFIG = {
        code: {
            type: "qr"
        }
    }

    function auth(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let object = {};
        formData.forEach((value, key) => object[key] = value);

        resources.auth.auth(object).then((res) => {
            localStorage.setItem("userToken", res.data.token);
            resources.user.read(1, 1, "email=" + formData.get("username")).then((r) => {
                setLoader(false)
                localStorage.setItem("userId", r.data.items[0].id);
                localStorage.setItem("userName", r.data.items[0].firstName);
                localStorage.setItem("userRole", "admin");
                setNavigate(true)

            }).catch((e) => {
                setLoader(false)
                setError(true)
            })


        }).catch((er) => {
            setLoader(false)
            setError(true)

        })
      

    }

    if (navigate) {
        return <Navigate to="/clients" replace={true}/>
    }

    return (
        <Stack minH={'100vh'} direction={{base: 'column', md: 'row'}}>
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Stack spacing={4} w={'full'} maxW={'md'}>
                    <Heading fontSize={'2xl'}>Sign in to your account</Heading>
                    <form onSubmit={auth}>
                        <FormControl id="email" isRequired>
                            <FormLabel>User name</FormLabel>
                            <Input type="text" name="username" required/>
                        </FormControl>
                        <FormControl id="password" isRequired>
                            <FormLabel>Password</FormLabel>
                            <Input type="password" name="password" required/>
                        </FormControl>
                        <Stack spacing={10} style={{marginTop: 20}}>
                            <Button colorScheme="brand" variant={'solid'} type="submit" isLoading={loader}>
                                Sign in
                            </Button>
                        </Stack>
                    </form>

                    {isVisible && <Alert status='error' variant='left-accent'>
                        <AlertIcon/>
                        <Box>
                            <AlertTitle>Error!</AlertTitle>
                            <AlertDescription>
                                User name or password incorrect
                            </AlertDescription>
                        </Box>
                        <CloseButton
                            alignSelf='flex-start'
                            position='absolute'
                            right={1}
                            top={1}
                            onClick={onClose}
                        />
                    </Alert>}
                </Stack>


            </Flex>

        </Stack>
    );
}
